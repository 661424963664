@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .linear_bg {
    background: linear-gradient(90deg, #e8b070 0%, #e2452e 100%);

    /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  }

  .form {
    position: relative;
    width: 100%;
    height: 3rem;
    margin-top: 35px;
  }

  .form2 {
    position: relative;
    width: 100%;
    height: 3rem;
    margin-top: 20px;
  }

  .err_msg {
    position: absolute;
    top: 50px;
    font-size: 13px;
    color: red;
  }

  .form_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
    background: none;
    padding: 1.25rem;
  }

  .form_input2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
    background: none;
    padding: 1.25rem;
  }

  .form_input:focus {
    border: 1px solid #e8b070;
    outline: none;
  }

  .chevron_down {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 20px;
    color: #525252;
    cursor: pointer;
  }

  .form_label {
    font-family: "Poppins", sans-serif;
    position: absolute;
    left: 1rem;
    top: -0.6rem;
    padding: 0 0.5rem;
    color: #bcbcbc;
    cursor: pointer;
    background-color: #ffffff;
    /* border: 1px solid black; */
    z-index: 1px;
  }

  .form_input:focus ~ .form_label,
  .form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
    display: none;
    border: #e8b070;
  }

  .form_input2:focus ~ .form_label,
  .form_input2:not(:placeholder-shown).form_input2:not(:focus) ~ .form_label {
    display: none;
    border: #e8b070;
  }

  .select_dropdown {
    position: absolute;
    padding: 5px;
    top: 50px;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    z-index: 1;
    border-radius: 5px;
  }

  .select_dropdown p {
    /* border-bottom:1px solid #e4e4e4 ; */
    padding: 5px;
    cursor: pointer;
  }

  .select_dropdown p:hover {
    background-color: #f3f3f3;
  }

  .selected_option {
    position: absolute;
    top: 13px;
    left: 20px;
  }

  .svg1 {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .svg2 {
    position: absolute;
    bottom: -45px;
    right: 0px;
  }

  .svg3 {
    position: absolute;
    bottom: -25px;
    right: 0px;
  }

  .svg4 {
    position: absolute;
    bottom: -20px;
    right: 0px;
  }

  .svg5 {
    position: absolute;
    bottom: -20px;
    right: 0px;
  }

  .svg6 {
    position: absolute;
    bottom: -20px;
    right: 0px;
  }

  .svg7 {
    position: absolute;
    bottom: -20px;
    right: 0px;
  }

  .svg_card {
    z-index: 2;
    position: absolute;
    right: 10px;
    bottom: 30px;
  }

  .svg_card2 {
    z-index: 2;
    position: absolute;
    left: -60px;
    bottom: -15px;
  }

  .svg_card3 {
    z-index: 2;
    position: absolute;
    right: -30px;
    top: -10px;
  }

  .svg_card4 {
    z-index: 2;
    position: absolute;
    left: -60px;
    top: -10px;
  }

  .sidebar_width {
    /* width: 293px; */
    width: 23%;
  }

  .sidebar_width2 {
    /* width: 293px; */
    width: 77%;
  }

  .sidebar_route_h {
    height: 50px;
  }

  .top_bar_btn {
    height: 35px;
    width: 99px;
  }

  .top_bar_btn2 {
    height: 35px;
    width: 129px;
  }

  .top_bar_btn3 {
    height: 35px;
    width: 137px;
  }

  .topbar_inpt {
    width: 172px;
    height: 40px;
    border-radius: 30px;
  }

  .search_png {
    top: 12px;
    right: 20px;
  }

  .no_eventCard {
    height: 547px;
    border-radius: 30px;
    border: 1px solid #e4e1e1;
  }

  .link_circle {
    width: 60px;
    height: 60px;
  }

  .centerItem {
    margin: 0 auto;
  }

  .noevent_message {
    width: 371px;
    line-height: 22px;
    text-align: center;
  }

  .eventCard {
    height: 94px;
    border-radius: 30px;
    border: 1px solid #e4e1e1;
  }

  .event_link {
    color: #bcbcbc;
    font-size: 18px;
    font-weight: 400;
  }

  .event_time {
    width: 86px;
    height: 25px;
    border: 1px solid #e4e1e1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 3px;
    justify-content: center;
    gap: 5px;
  }

  .even_time_text {
    font-size: 10px;
    font-weight: 500;
  }

  .event_users {
    width: 64px;
    height: 25px;
    border: 1px solid #e4e1e1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 3px;
    justify-content: center;
    gap: 5px;
  }

  .width100 {
    width: 100px;
  }

  .width241 {
    width: 241px;
  }

  /* new line */
/* 
  .calender_desc {
    font-size: 20px;
    text-align: center;
    line-height: 29.23px;
    font-weight: 400;
    width: 792px;
  } */

  /* .widthcalenderdesc {
    width: 700px;
  } */

/* 
  .mobile_ads_card02 {
    height: 430.96px;
    width: 348.09px;
  } */

  .mobile_ads_card03 {
    height: 377.09px;
    width: 304.58px;
  }

  .mobile_ads_card_img {
    width: 334px;
    height: 543.38px;
    margin: 0 auto;
    top: 25.08px;
  }

  .mobile_ads_card_img1 {
    width: 301.5px;
    height: 116.18px;
    margin: 0 auto;
  }

  .mobile_ads_card_img2 {
    width: 320px;
    height: 182.02px;
    margin: 0 auto;
    top: 290px;
  }

  .play_store_cont {
    width: 174px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 20px 0px #0000001f;
    border-radius: 14px;
  }

  .app_store_cont {
    width: 174px;
    height: 56px;
  }

  .play_store_cont_img1 {
    width: 130px;
    height: 32px;
  }

  .locationBanner_cont {
    background: url("./asset/landingImages/Event card frame.png");
    height: 774px;
  }

  .benefit_btn {
    padding: 8px 16px;
    border-radius: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid;
    border-color: linear-gradient(
      270deg,
      rgba(209, 236, 228, 0.5) 0%,
      #e3a5c7 100%
    );
  }

  .PolyganBanner_img {
    width: 29.94px;
    height: 29.94px;
  }

  .animated_text {
    font-size: 90.62px;
    font-weight: 500;
  }

  .animated_text2 {
    font-size: 90.62px;
    font-weight: 300;
  }

  .animated_text,
  .animated_text2 {
    transition: all 0.3s ease-in-out;
  }


  .circle_frame {
    height: 65px;
    width: 65px;
  }

  .lineframe {
    height: 488.48px;
    left: 120px;
  }


  .css-17mg6aq:last-of-type {
    border: none;
  }

  .css-17mg6aq {
    border: none;
  }

  .fax_icons {
    width: 30.18px;
    height: 25px;
  }

  .fax_icons2 {
    width: 35px;
    height: 35px;
  }

  .fax_text {
    font-size: 80px;
    font-weight: 600;
    background: linear-gradient(180deg, #6d4f98 0%, #e3a5c7 100%);
    background-clip: text;
    -webkit-background-clip: text; /* For WebKit-based browsers */
    color: transparent;
    display: inline-block;
  }

  .fax_p {
    font-size: 24px;
    font-weight: 400;
    line-height: 29.23px;
    color: #19191c;
    width: 435px;
    white-space: nowrap;
  }
/* Gradient text styling */
.mailing_list {
  background: linear-gradient(180deg, #6d4f98 0%, #e3a5c7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  line-height: 1.5;
  overflow: visible;
}

  
  .mailing_icon {
    width: 48.92px;
    height: 48.92px;
  }

  .mailing_btn {
    width: 108px;
    height: 41px;
    border-radius: 14px;
    background: linear-gradient(180deg, #6d4f98 0%, #e3a5c7 100%);
    padding: 25px 25px;
    color: white;
  }

  .mailing_btn img {
    width: 24px;
    height: 24px;
  }

  .mailing_inpt_cont {
    border: 0.5px solid;    
    padding: 2px; /* Adjust this for border thickness */
    border-radius: 15px;
    border-color:#6d4f98;

  }
  
  .mailing_inpt_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px; /* Inner div radius should be slightly smaller */
    height: 100%;
    padding: 10px;

  }

  .bgFeature{
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 47%, #F0F9F6 100%);

  }
  
}
